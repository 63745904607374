import React from 'react'

interface EmbededUrlProps {
    url: string
    width: string
    height: string
}

export default function EmbdededUrl(props: EmbededUrlProps) {
    return (
        <iframe
            width={props.width || '100%'}
            height={props.height || '100%'}
            src={props.url}
            frameBorder="0"
        ></iframe>
    )
}
