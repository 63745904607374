import ChevronImg from '../images/chevron_left.svg'
import MPCVideoPlayer from './mpcvideoplayer'
import React, { useEffect, useRef, useState } from 'react'
import _debounce from 'lodash/debounce'
import classNames from 'classnames'
import * as styles from '../components/carousel.module.less'
import {
    GatsbyImage,
    getImage,
    getSrc,
    IGatsbyImageData,
} from 'gatsby-plugin-image'
import EmbdededUrl from './embededUrl'

interface CarouselProps {
    media: {
        label: string
        description: string
        mediaObject
        poster
        mpcVideoId
    }[]
}

const openFullImage = url => {
    window.open(url, '_blank')
}

export default function Carousel(props: CarouselProps) {
    const [lastVideoThatPlayed, setLastVideoThatPlayed] = useState([])

    useEffect(() => {
        const onWindowMessage = (ev: MessageEvent) => {
            if (ev.data.type == 'mpcStatus' && ev.data.state == 'play') {
                setLastVideoThatPlayed(ev.data.id)
            }
        }

        window.addEventListener('message', onWindowMessage)
        return () => window.removeEventListener('message', onWindowMessage)
    }, [])

    // Getting component props
    const { media = [] } = props

    // Array with all carousel items refs
    const refs = useRef<Array<HTMLDivElement | null>>([])

    //ref for the carousel track. needed for scroll handler
    const trackRef = useRef<HTMLDivElement>(null)

    // Initializing state
    const [itemInView, setItemInView] = useState<number>(0)

    const scrollCarouselByButtons = direction => {
        let newItemInView = itemInView
        if (direction == 'right') {
            if (itemInView + 1 <= media.length) {
                newItemInView = itemInView + 1
            }
        }
        if (direction == 'left') {
            if (itemInView - 1 >= 0) {
                newItemInView = itemInView - 1
            }
        }

        // Getting element from refs
        const domItemInView = refs.current[newItemInView] as HTMLDivElement

        // Scrolling to element
        domItemInView.scrollIntoView({ behavior: 'smooth', block: 'nearest' })

        // Setting new current element.
        setItemInView(newItemInView)
    }
    const scrollCarousel = () => {
        //this is called when the carousel is scrolled manually
        const track = trackRef.current as HTMLDivElement
        const newItemInView = Math.round(track.scrollLeft / track.offsetWidth)
        // Setting new current element
        setItemInView(newItemInView)

        const mpcVideoIframes = document.querySelectorAll<HTMLIFrameElement>(
            'iframe[data-mpcvideoid]'
        )
        for (const videoIframe of mpcVideoIframes) {
            videoIframe.contentWindow?.postMessage({
                type: 'mpcAction',
                action: 'pause',
            })
        }
    }
    return (
        <div className={styles.carouselComponent}>
            <div
                className={classNames(styles.navigation, styles.left)}
                data-visible={itemInView !== 0}
                style={{ backgroundImage: 'url(' + ChevronImg + ')' }}
                onClick={() => scrollCarouselByButtons('left')}
            ></div>
            <div
                className={classNames(styles.navigation, styles.right)}
                data-visible={itemInView !== media.length - 1}
                style={{ backgroundImage: 'url(' + ChevronImg + ')' }}
                onClick={() => scrollCarouselByButtons('right')}
            ></div>
            <div
                ref={trackRef}
                className={styles.track}
                onScroll={_debounce(scrollCarousel, 100)}
            >
                {media.map((item, index) => (
                    <div
                        key={index}
                        className={styles.item}
                        ref={el => (refs.current[index] = el)}
                    >
                        {item.mediaObject &&
                            item.mediaObject.internal.mediaType.includes(
                                'image'
                            ) && (
                                <GatsbyImage
                                    className={styles.image}
                                    onClick={() =>
                                        openFullImage(
                                            item.mediaObject.childImageSharp
                                                .original.src
                                        )
                                    }
                                    image={
                                        getImage(
                                            item.mediaObject
                                        ) as IGatsbyImageData
                                    }
                                    alt=""
                                />
                            )}
                        {item.poster &&
                            item.mediaObject &&
                            item.mediaObject.internal.mediaType.includes(
                                'video'
                            ) && (
                                <video
                                    style={{
                                        backgroundColor: '#000',
                                    }}
                                    poster={item.poster.publicURL}
                                    src={item.mediaObject.publicURL}
                                    controls
                                />
                            )}
                        {item.mpcVideoId != null && (
                            <MPCVideoPlayer
                                mpcVideoId={item.mpcVideoId}
                            ></MPCVideoPlayer>
                        )}
                        {item.embededUrl != null && (
                            <EmbdededUrl
                                url={item.embededUrl}
                                width={item.embededUrlWidth}
                                height={item.embededUrlHeight}
                            />
                        )}
                        {(item.label || item.description) && (
                            <div
                                className={styles.labeling}
                                data-hidden={
                                    lastVideoThatPlayed == item.mpcVideoId
                                }
                            >
                                <div className={styles.labelTitle}>
                                    {item.label}
                                </div>
                                <div className={styles.labelSubTitle}>
                                    {item.description}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {props.media.length > 1 && (
                <div
                    className={styles.pagination}
                    data-counter={props.media.length}
                >
                    {props.media.map((item, index) => (
                        <div
                            key={index}
                            className={styles.item}
                            data-current={index == itemInView}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}
