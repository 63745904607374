// extracted by mini-css-extract-plugin
export var carouselComponent = "carousel-module--carouselComponent--pLUAf";
export var track = "carousel-module--track--7RRaF";
export var item = "carousel-module--item--1nTWl";
export var image = "carousel-module--image--qeJf7";
export var labeling = "carousel-module--labeling--Rk3vQ";
export var labelTitle = "carousel-module--labelTitle--UEAee";
export var labelSubTitle = "carousel-module--labelSubTitle--8lbXo";
export var navigation = "carousel-module--navigation--JdnlY";
export var left = "carousel-module--left--FIj5T";
export var right = "carousel-module--right--6ZG+D";
export var pagination = "carousel-module--pagination--KTGY4";