import DefaultProjectPageTemplate from './project/default'
import React from 'react'
import SEOProjectPageTemplate from './project/seo'
import _get from 'lodash/get'
import { graphql } from 'gatsby'

const templates = {
    default: DefaultProjectPageTemplate,
    seo: SEOProjectPageTemplate,
}

const ProjectPageTemplate: React.FC = props => {
    const project = _get(props, 'data.projectBySlug')
    const Template = templates[project.template]
    return <Template {...props} />
}

export default ProjectPageTemplate

export const pageQuery = graphql`
    query projectQuery($slug: String!, $id: String!) {
        siteMetadata: cmsSiteMetadata {
            title
            description
            image {
                publicURL
            }
            twitterCreator
        }
        jobsByProjectId: allCmsJob(filter: { project: { id: { eq: $id } } }) {
            nodes {
                title
                location {
                    city
                }
            }
        }
        projectBySlug: cmsProject(slug: { eq: $slug }) {
            defaultTitle
            heroTitle
            metadataPageTitle
            heroSubtitle
            slug
            id
            themeColor
            themeVariant
            template
            headerImage {
                childImageSharp {
                    gatsbyImageData(width: 4000, quality: 100)
                }
            }
            overviewText
            contentSections {
                id
                anchor
                media {
                    label
                    mediaObject {
                        childImageSharp {
                            gatsbyImageData(width: 960, quality: 100)
                        }
                    }
                    mpcVideoId
                    embededUrl
                    embededUrlWidth
                    embededUrlHeight
                }
                title
                content
                imageFloat
                imageMaxWidth
                hasCta
            }
            ctaButtonLabel
            team
            systemRequirements
            status
            externalUrl
            mobileExternalUrl
            category {
                name
            }
            relatedProjects {
                id
                slug
                heroSubtitle
                themeColor
                themeVariant
                cardImage {
                    childImageSharp {
                        gatsbyImageData(width: 320, quality: 100)
                    }
                }
                externalUrl
                mobileExternalUrl
                defaultTitle
                cardTitle
            }
            updated
            media {
                name
                description
                label
                length
                poster {
                    publicURL
                }
                mpcVideoId
                embededUrl
                embededUrlWidth
                embededUrlHeight
                mediaObject {
                    childImageSharp {
                        original {
                            src
                        }
                        gatsbyImageData(width: 960, quality: 100)
                    }
                    internal {
                        mediaType
                    }
                }
            }
            socialHashtag
            socialShareMessage
            metadataSocialTitle
            metadataSocialDescription
            metadataSocialImage {
                url
            }
            metadataSocialImageFallback: cardImage {
                url
            }
            curatedTweetsListId
            tryButtonInfoText
        }
    }
`
