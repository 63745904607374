import React from 'react'

interface MPCVideoPlayerProps {
    mpcVideoId: string
}

export default function MPCVideoPlayer(props: MPCVideoPlayerProps) {
    return (
        <iframe
            title="Adobe Video Publishing Cloud Player"
            width="100%"
            height="100%"
            src={`https://video.tv.adobe.com/v/${props.mpcVideoId}/?enable10seconds=on&quality=9&hidetitle=true`}
            frameBorder="0"
            allowFullScreen
            scrolling="no"
            data-mpcvideoid={props.mpcVideoId}
        ></iframe>
    )
}
