import '../../../components/hero-header.less'
import '../../../components/projects-grid.less'
import Carousel from '../../../components/carousel'
import Copylink from '../../../components/copylink'
import Layout from '../../../components/layout'
import ProjectCard from '../../../components/project-card'
import React, { useEffect, useState } from 'react'
import TwitterCollectionViewer from '../../../components/twittercollectionviewer'
import _get from 'lodash/get'
import classNames from 'classnames'
import queryString from 'query-string'
import * as styles from '../../../components/project-page.module.less'
import { Button, Provider } from '@adobe/react-spectrum'
import { isMobile } from '../../../utils/isMobile'
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
} from 'react-share'
import { Link } from 'gatsby'
import { SEO } from '../../../components/seo'
import { getAbsoluteUrl } from '../../../utils/getAbsoluteUrl'
import { parseDate } from '../../../utils/parseDate'
import { richTextToHtml } from '../../../utils/richTextToHtml'
import { richTextToPlainText } from '../../../utils/richTextToPlainText'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const DefaultProjectPageTemplate: React.FC = props => {
    // Getting data
    const project = _get(props, 'data.projectBySlug')
    const siteMetadata = _get(props, 'data.siteMetadata')

    // Tab state
    const [selectedTab, setSelectedTab] = useState<string>('overview')

    const updateTab = tab => {
        window.location.hash = 'tab=' + tab
        setSelectedTab(tab)
    }
    // Getting project URL
    const projectUrl = getAbsoluteUrl(`/projects/${project.slug}`)

    const heroTitle = project.heroTitle || project.defaultTitle
    const metadataPageTitle = project.metadataPageTitle || project.defaultTitle

    // Getting an array of hashtags
    const hashtags = [project.socialHashtag, 'AdobeLabs'].reduce(
        (hashtags: string[], hashtag: string) => {
            if (hashtag) {
                hashtags.push(
                    hashtag.startsWith('#') ? hashtag.substring(1) : hashtag
                )
            }
            return hashtags
        },
        []
    )

    // Hashtags array with leading #
    const hashtagsWithHash = hashtags.map(hashtag => `#${hashtag}`)

    // Getting social share title and message
    const socialShareTitle = project.metadataSocialTitle || project.defaultTitle
    const socialShareMessage =
        project.socialShareMessage || richTextToPlainText(project.heroSubtitle)
    const socialShareMessageWithHashtags = `${socialShareMessage} ${hashtagsWithHash.join(
        ' '
    )}`

    // Filtering media that we support
    const projectMedia = project.media.filter(
        item => item.mediaObject || item.mpcVideoId || item.embededUrl
    )

    // Theme props
    const themeVariant = project.themeVariant || 'dark'

    // Getting bg image
    const bgImage = getImage(project.headerImage)

    const hasTabs =
        project.relatedProjects.length > 0 || project.curatedTweetsListId
    function trackShareClick(channel: string) {
        if (!window['digitalData']) {
            return
        }
        // Set below properties :
        digitalData.primaryEvent = {
            eventInfo: {
                eventName: 'labs:SneakShareClick:' + channel,

                eventAction: 'SneakShare',
            },
        }
        // Invoke below track function when share is clicked
        _satellite.track('event', {
            digitalData: digitalData._snapshot(),
        })
    }

    useEffect(() => {
        const checkHashAndSetTab = () => {
            const hashTab = queryString.parse(location.hash).tab as string
            if (
                hashTab &&
                ['overview', 'related', 'discussion'].includes(hashTab)
            ) {
                setSelectedTab(hashTab)
            } else {
                setSelectedTab('overview')
            }
        }

        window.addEventListener('hashchange', checkHashAndSetTab, false)
        checkHashAndSetTab()
        return () => {
            window.removeEventListener('hashchange', checkHashAndSetTab, false)
        }
    }, [])

    return (
        <Layout>
            <SEO
                title={metadataPageTitle}
                description={
                    richTextToPlainText(project.heroSubtitle) as string
                }
                metadataSocialTitle={project.metadataSocialTitle}
                metadataSocialDescription={project.metadataSocialDescription}
                metadataSocialImage={
                    project.metadataSocialImage ??
                    project.metadataSocialImageFallback
                }
                siteMetadata={siteMetadata}
            />
            <Provider colorScheme="dark">
                <div
                    className={classNames('hero_header', themeVariant)}
                    style={{
                        backgroundColor: project.themeColor,
                    }}
                >
                    {bgImage && (
                        <GatsbyImage
                            image={bgImage}
                            alt=""
                            className="hero_header_image"
                        />
                    )}

                    <div className="hero_header_content">
                        <div className="breadcrumbs">
                            <Link to="/">Home</Link>
                            <a href="">{heroTitle}</a>
                        </div>

                        <div className="title">{heroTitle}</div>
                        <div className="subtitle">
                            {richTextToHtml(project.heroSubtitle || [])}
                        </div>
                    </div>
                </div>
            </Provider>
            <div
                className={classNames(
                    styles.project_content,
                    hasTabs && styles.hasTabs
                )}
            >
                {hasTabs && (
                    <div className="tabs">
                        <div
                            className="tab"
                            data-tab="overview"
                            role="button"
                            data-selected={selectedTab === 'overview'}
                            onClick={() => updateTab('overview')}
                        >
                            Overview
                        </div>

                        {project.relatedProjects.length > 0 && (
                            <div
                                className="tab"
                                data-tab="related"
                                data-selected={selectedTab === 'related'}
                                onClick={() => updateTab('related')}
                            >
                                Related
                            </div>
                        )}

                        {project.curatedTweetsListId && (
                            <div
                                className="tab"
                                data-tab="discussion"
                                data-selected={selectedTab === 'discussion'}
                                onClick={() => updateTab('discussion')}
                            >
                                Discussion
                            </div>
                        )}
                    </div>
                )}

                <div
                    className={styles.project_tab}
                    data-tab="overview"
                    data-selected={selectedTab == 'overview'}
                >
                    <div
                        className={styles.overviewContent}
                        data-carousel={project.media.length > 0}
                    >
                        {selectedTab == 'overview' && projectMedia.length > 0 && (
                            <div className={styles.carousel}>
                                <Carousel media={projectMedia}></Carousel>
                            </div>
                        )}

                        <div className={styles.overview}>
                            {richTextToHtml(project.overviewText || [])}
                        </div>
                        <div className={styles.sidebar}>
                            {project.externalUrl ||
                            project.mobileExternalUrl ? (
                                <section className={styles.externalLink}>
                                    <a
                                        target="blank"
                                        href={
                                            isMobile() &&
                                            props.mobileExternalUrl
                                                ? props.mobileExternalUrl
                                                : props.externalUrl
                                        }
                                    >
                                        <Button
                                            key={'cta_' + project.slug}
                                            variant="cta"
                                            target="_blank"
                                        >
                                            Try it out
                                        </Button>
                                    </a>
                                    {project.tryButtonInfoText && (
                                        <span className={styles.note}>
                                            {project.tryButtonInfoText}
                                        </span>
                                    )}
                                </section>
                            ) : (
                                ''
                            )}

                            {project.systemRequirements ? (
                                <section>
                                    <span className={styles.subtitle}>
                                        System Requirements
                                    </span>
                                    {project.systemRequirements}
                                </section>
                            ) : (
                                ''
                            )}

                            {project.updated ? (
                                <section>
                                    <span className={styles.subtitle}>
                                        Updated
                                    </span>
                                    {parseDate(project.updated).format(
                                        'MMM D, Y'
                                    )}
                                </section> //todo format this
                            ) : (
                                ''
                            )}

                            {project.category.length > 0 ? (
                                <section>
                                    <span className={styles.subtitle}>
                                        Category
                                    </span>
                                    {project.category.map((cat, index) => (
                                        <span key={index} className="cat">
                                            {cat.name},&nbsp;
                                        </span>
                                    ))}
                                </section>
                            ) : (
                                ''
                            )}

                            {project.status ? (
                                <section>
                                    <span className={styles.subtitle}>
                                        Status
                                    </span>
                                    {project.status}
                                </section>
                            ) : (
                                ''
                            )}

                            {project.team ? (
                                <section>
                                    <span className={styles.subtitle}>
                                        Team
                                    </span>
                                    {richTextToHtml(project.team)}
                                </section>
                            ) : (
                                ''
                            )}

                            <section className={styles.twitterfeed}>
                                <span className={styles.subtitle}>Share</span>

                                <TwitterShareButton
                                    url={projectUrl}
                                    title={socialShareMessage}
                                    hashtags={hashtags}
                                    className={styles.shareButton}
                                    onClick={() => trackShareClick('twitter')}
                                >
                                    <TwitterIcon size={32} round={true} />
                                </TwitterShareButton>
                                <FacebookShareButton
                                    url={projectUrl}
                                    quote={socialShareMessage}
                                    hashtag={hashtagsWithHash[0]}
                                    className={styles.shareButton}
                                    onClick={() => trackShareClick('facebook')}
                                >
                                    <FacebookIcon size={32} round={true} />
                                </FacebookShareButton>
                                <LinkedinShareButton
                                    url={projectUrl}
                                    title={socialShareTitle}
                                    summary={socialShareMessageWithHashtags}
                                    className={styles.shareButton}
                                    onClick={() => trackShareClick('linkedin')}
                                >
                                    <LinkedinIcon size={32} round={true} />
                                </LinkedinShareButton>
                                <EmailShareButton
                                    url={projectUrl}
                                    subject={project.defaultTitle}
                                    body={`${socialShareMessageWithHashtags}\n`}
                                    className={styles.shareButton}
                                    onClickCapture={() =>
                                        trackShareClick('email')
                                    }
                                >
                                    <EmailIcon size={32} round={true} />
                                </EmailShareButton>
                                <Copylink
                                    url={projectUrl}
                                    onCopy={() => trackShareClick('clipboard')}
                                ></Copylink>
                            </section>
                        </div>
                    </div>
                </div>
                {project.relatedProjects ? (
                    <div
                        className={styles.project_tab}
                        data-tab="related"
                        data-selected={selectedTab == 'related'}
                    >
                        <div className="projects_grid">
                            {project.relatedProjects.map(relatedProject => (
                                <ProjectCard
                                    key={relatedProject.slug}
                                    {...relatedProject}
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {project.curatedTweetsListId ? (
                    <div
                        className={styles.project_tab}
                        data-tab="discussion"
                        data-selected={selectedTab == 'discussion'}
                    >
                        <TwitterCollectionViewer
                            placeholder={'Curated tweets for ' + heroTitle}
                            curatedTweetsListId={project.curatedTweetsListId}
                            socialHashtag={project.socialHashtag}
                        ></TwitterCollectionViewer>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </Layout>
    )
}

export default DefaultProjectPageTemplate
