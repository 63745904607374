// extracted by mini-css-extract-plugin
export var project_content = "project-page-seo-module--project_content--FaRxX";
export var subheader = "project-page-seo-module--subheader--NHIVE";
export var breadcrumbs = "project-page-seo-module--breadcrumbs--n-ICD";
export var fixed = "project-page-seo-module--fixed--SUQdY";
export var externalLink = "project-page-seo-module--externalLink--6VtVy";
export var tabs = "project-page-seo-module--tabs--ChVnQ";
export var note = "project-page-seo-module--note--m65PG";
export var project_tab = "project-page-seo-module--project_tab--zM9Dp";
export var overviewContent = "project-page-seo-module--overviewContent--lOVsD";
export var carousel = "project-page-seo-module--carousel--NKAui";
export var overview = "project-page-seo-module--overview--sSfrm";
export var title = "project-page-seo-module--title--UY6A3";
export var embeded_asset = "project-page-seo-module--embeded_asset--h4s6z";
export var discussion = "project-page-seo-module--discussion--+EmCa";
export var pleasetwitt = "project-page-seo-module--pleasetwitt--5sN+4";
export var twitterfeed = "project-page-seo-module--twitterfeed--oYohd";
export var hashtag = "project-page-seo-module--hashtag--MHl+P";
export var shareButton = "project-page-seo-module--shareButton--CYBZf";
export var contentSection = "project-page-seo-module--contentSection--tpnT7";
export var anchor = "project-page-seo-module--anchor--THVo+";
export var f10 = "project-page-seo-module--f10--ZrwDC";
export var f20 = "project-page-seo-module--f20--bbfch";
export var f30 = "project-page-seo-module--f30--Pfltr";
export var f40 = "project-page-seo-module--f40--N+blr";
export var f50 = "project-page-seo-module--f50---YZjt";
export var f60 = "project-page-seo-module--f60--kmLtL";
export var f70 = "project-page-seo-module--f70--1q8WA";
export var f80 = "project-page-seo-module--f80--rikPe";
export var f90 = "project-page-seo-module--f90--woyFe";
export var imageFloatleft = "project-page-seo-module--imageFloatleft--jkUsk";
export var imageFloatright = "project-page-seo-module--imageFloatright--rKEGS";
export var imageFloatnone = "project-page-seo-module--imageFloatnone--b0bZ1";
export var sectionImage = "project-page-seo-module--sectionImage--CErBT";
export var isVideotrue = "project-page-seo-module--isVideotrue--761Jt";
export var mediaColumn = "project-page-seo-module--mediaColumn--J4Geh";
export var contentColumn = "project-page-seo-module--contentColumn--7pqhq";
export var details = "project-page-seo-module--details--ZFEPx";
export var grid = "project-page-seo-module--grid--ULtSy";
export var detailSection = "project-page-seo-module--detailSection--PXe32";
export var projectName = "project-page-seo-module--projectName--q2CRh";
export var detailName = "project-page-seo-module--detailName--9sjtj";
export var detailContent = "project-page-seo-module--detailContent--90qLw";
export var cat = "project-page-seo-module--cat--SlQUB";