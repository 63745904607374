import React, { useState } from 'react'
import classNames from 'classnames'
import * as styles from './twittercollectionviewer.module.less'
import { ProgressCircle } from '@adobe/react-spectrum'
import { Timeline } from 'react-twitter-widgets'

interface curatedTweetsListId {
    placeholder: string
    curatedTweetsListId: string
    socialHashtag: string
}

export default function TwitterCollectionViewer(props: curatedTweetsListId) {
    const [iframeIsStyled, setIframeStyleBit] = useState<boolean>(() => {
        return false
    })
    const addStyleToDocument = (styleString, doc) => {
        const style = doc.createElement('style')
        style.textContent = styleString
        doc.head.append(style)
    }
    const styleIframe = () => {
        const iframe = document.querySelector('#twitter-widget-0')
        addStyleToDocument(
            `
                        .timeline-Header{
                            display:none;
                        }
                        .timeline-Tweet-text{
                            font-size:16px!important;
                            line-height:24px!important;
                        }
                        `,
            iframe?.contentDocument
        )
        setIframeStyleBit(true)
    }

    return (
        <>
            <div
                className={classNames(
                    styles.loading_message,
                    iframeIsStyled && styles.hidden
                )}
            >
                <ProgressCircle
                    aria-label="Loading tweets"
                    isIndeterminate
                ></ProgressCircle>
                <br />
                Loading tweets...
            </div>
            <div
                className={classNames(
                    styles.feedContainer,
                    iframeIsStyled && styles.visible
                )}
            >
                {props.socialHashtag ? (
                    <div className={styles.twitterlink}>
                        View latest{' '}
                        <a
                            target="blank"
                            href={
                                'https://twitter.com/hashtag/' +
                                props.socialHashtag +
                                '?f=live'
                            }
                        >
                            #{props.socialHashtag}
                        </a>{' '}
                        tweets
                    </div>
                ) : (
                    ''
                )}
                <Timeline
                    dataSource={{
                        id: props.curatedTweetsListId,
                    }}
                    onLoad={styleIframe}
                />
            </div>
        </>
    )
}
