// extracted by mini-css-extract-plugin
export var project_content = "project-page-module--project_content--P6CYj";
export var hasTabs = "project-page-module--hasTabs--A6u-T";
export var project_tab = "project-page-module--project_tab--EXuVY";
export var overviewContent = "project-page-module--overviewContent--gp2xO";
export var carousel = "project-page-module--carousel--7LxHe";
export var overview = "project-page-module--overview--3-nVt";
export var title = "project-page-module--title--pY1f4";
export var embeded_asset = "project-page-module--embeded_asset--G6CXj";
export var sidebar = "project-page-module--sidebar--W6vrl";
export var externalLink = "project-page-module--externalLink--fQL2w";
export var twitterfeed = "project-page-module--twitterfeed--SeLZU";
export var twitter_card = "project-page-module--twitter_card--OB6Ky";
export var subtitle = "project-page-module--subtitle--jXaey";
export var note = "project-page-module--note--ePext";
export var discussion = "project-page-module--discussion--nyJgp";
export var pleasetwitt = "project-page-module--pleasetwitt--tuUMj";
export var hashtag = "project-page-module--hashtag--XFG2F";
export var shareButton = "project-page-module--shareButton--oTKGn";