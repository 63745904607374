import React, { useState } from 'react'
import classNames from 'classnames'
import linkImage from '../images/link.svg'
import * as styles from '../components/copylink.module.less'

interface CopyLinkProps {
    url: string
    onCopy?: () => void
}

export default function CopyLink(props: CopyLinkProps) {
    const [notificationClass, setNotificationState] = useState<string>('')
    const copyLink = url => {
        // Copying to clipboard using a temp input element.
        const tempInput = document.createElement('input')
        tempInput.value = url
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand('copy')
        document.body.removeChild(tempInput)

        // Calling if on click specified
        if (props.onCopy) {
            props.onCopy()
        }

        // Showing notification
        setNotificationState('visible')

        // Hiding notification
        setTimeout(function () {
            setNotificationState('')
        }, 5000)
    }
    return (
        <div
            className={styles.copylinkComponent}
            data-copied="true"
            title="Copy Link"
            style={{
                backgroundImage: 'url(' + linkImage + ')',
            }}
            onClick={() => copyLink(props.url)}
        >
            <span
                className={classNames(
                    styles.notification,
                    styles[notificationClass]
                )}
            >
                Link copied
            </span>
        </div>
    )
}
