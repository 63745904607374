import '../../../components/hero-header-seo.less'
import '../../../components/projects-grid.less'
import Carousel from '../../../components/carousel'
import Copylink from '../../../components/copylink'
import Layout from '../../../components/layout'
import MPCVideoPlayer from '../../../components/mpcvideoplayer'
import ProjectCard from '../../../components/project-card'
import React, { Key, useEffect, useState } from 'react'
import TwitterCollectionViewer from '../../../components/twittercollectionviewer'
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import classNames from 'classnames'
import queryString from 'query-string'
import * as styles from '../../../components/project-page-seo.module.less'
import {
    Button,
    Divider,
    Flex,
    Item,
    Provider,
    TabList,
    Tabs,
    View,
} from '@adobe/react-spectrum'
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
} from 'react-share'
import { Link } from 'gatsby'
import { SEO } from '../../../components/seo'
import { getAbsoluteUrl } from '../../../utils/getAbsoluteUrl'
import { isMobile } from '../../../utils/isMobile'
import { parseDate } from '../../../utils/parseDate'
import { richTextToHtml } from '../../../utils/richTextToHtml'
import { richTextToPlainText } from '../../../utils/richTextToPlainText'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import EmbdededUrl from '../../../components/embededUrl'

const SEOProjectPageTemplate: React.FC = props => {
    // Getting data
    const project = _get(props, 'data.projectBySlug')
    const siteMetadata = _get(props, 'data.siteMetadata')

    // Tab state
    const [selectedTab, setSelectedTab] = useState<string | Key>('overview')

    // Defining tabs
    const tabs = [
        {
            id: 'overview',
            title: 'Overview',
        },
        ...(project.relatedProjects.length > 0
            ? [
                  {
                      id: 'related',
                      title: 'Related',
                  },
              ]
            : []),
        ...(project.curatedTweetsListId
            ? [
                  {
                      id: 'discussion',
                      title: 'Discussion',
                  },
              ]
            : []),
    ]

    // Getting project URL
    const projectUrl = getAbsoluteUrl(`/projects/${project.slug}`)

    const heroTitle = project.heroTitle || project.defaultTitle
    const metadataPageTitle = project.metadataPageTitle || project.defaultTitle

    // Getting an array of hashtags
    const hashtags = [project.socialHashtag, 'AdobeLabs'].reduce(
        (hashtags: string[], hashtag: string) => {
            if (hashtag) {
                hashtags.push(
                    hashtag.startsWith('#') ? hashtag.substring(1) : hashtag
                )
            }
            return hashtags
        },
        []
    )

    // Hashtags array with leading #
    const hashtagsWithHash = hashtags.map(hashtag => `#${hashtag}`)

    // Getting social share title and message
    const socialShareTitle = project.metadataSocialTitle || project.defaultTitle
    const socialShareMessage =
        project.socialShareMessage || richTextToPlainText(project.description)
    const socialShareMessageWithHashtags = `${socialShareMessage} ${hashtagsWithHash.join(
        ' '
    )}`

    // Filtering media that we support
    const projectMedia = project.media.filter(
        item => item.mediaObject || item.mpcVideoId
    )

    // Theme props
    const themeVariant = project.themeVariant || 'dark'

    // Getting bg image
    const bgImage = getImage(project.headerImage)

    const hasTabs =
        project.relatedProjects.length > 0 || project.curatedTweetsListId

    function trackShareClick(channel: string) {
        if (!window['digitalData']) {
            return
        }
        // Set below properties :
        digitalData.primaryEvent = {
            eventInfo: {
                eventName: 'labs:SneakShareClick:' + channel,
                eventAction: 'SneakShare',
            },
        }
        // Invoke below track function when share is clicked
        _satellite.track('event', {
            digitalData: digitalData._snapshot(),
        })
    }
    const [isSubHeaderFixed, setSubHeaderFixed] = useState<boolean>(false)

    useEffect(() => {
        const checkHashAndSetTab = () => {
            const hashTab = queryString.parse(location.hash).tab as string
            if (
                hashTab &&
                ['overview', 'related', 'discussion'].includes(hashTab)
            ) {
                setSelectedTab(hashTab)
            } else {
                setSelectedTab('overview')
            }
        }

        window.addEventListener('hashchange', checkHashAndSetTab, false)

        if (!isMobile() && project.externalUrl) {
            const checkToFixSubHeader = _debounce(function () {
                if (window.scrollY > 328) {
                    setSubHeaderFixed(true)
                } else {
                    setSubHeaderFixed(false)
                }
            })

            window.addEventListener('scroll', checkToFixSubHeader)
        }

        checkHashAndSetTab()
        return () => {
            window.removeEventListener('hashchange', checkHashAndSetTab, false)
        }
    }, [])
    const scrollTop = () => {
        window.scrollTo(0, 0)
    }
    return (
        <Layout>
            <SEO
                title={metadataPageTitle}
                description={richTextToPlainText(project.description) as string}
                metadataSocialTitle={project.metadataSocialTitle}
                metadataSocialDescription={project.metadataSocialDescription}
                metadataSocialImage={
                    project.metadataSocialImage ??
                    project.metadataSocialImageFallback
                }
                siteMetadata={siteMetadata}
            />
            <Provider colorScheme="dark">
                <div
                    className={classNames('hero_header', themeVariant)}
                    style={{
                        backgroundColor: project.themeColor,
                    }}
                >
                    {bgImage && (
                        <GatsbyImage
                            image={bgImage}
                            alt=""
                            className="hero_header_image"
                        />
                    )}

                    <div className="hero_header_content">
                        <div className="breadcrumbs">
                            <Link to="/">Home</Link>
                            <a href="">{project.defaultTitle}</a>
                        </div>
                        <h1>{heroTitle}</h1>
                        <div className="subtitle">
                            {project.heroSubtitle &&
                                richTextToHtml(project.heroSubtitle || [])}
                        </div>
                    </div>
                </div>
            </Provider>
            <div className={classNames(styles.project_content)}>
                {(project.externalUrl ||
                    project.mobileExternalUrl ||
                    hasTabs) && (
                    <Flex
                        direction="row"
                        minHeight="size-1000"
                        alignItems="center"
                        justifyContent="space-between"
                        UNSAFE_className={classNames(
                            styles.subheader,
                            isSubHeaderFixed && styles.fixed
                        )}
                    >
                        <View UNSAFE_className={styles.breadcrumbs}>
                            <div className="breadcrumbs">
                                <Link to="/">Home</Link>
                                <a href="#" onClick={() => scrollTop()}>
                                    {project.defaultTitle}
                                </a>
                            </div>
                        </View>
                        <View UNSAFE_className={styles.tabs}>
                            {hasTabs && (
                                <Tabs
                                    isQuiet
                                    items={tabs}
                                    selectedKey={selectedTab}
                                    onSelectionChange={setSelectedTab}
                                    marginBottom="size-100"
                                >
                                    <TabList>
                                        {(tab: {
                                            id: string
                                            title: string
                                        }) => (
                                            <Item key={tab.id}>
                                                {tab.title}
                                            </Item>
                                        )}
                                    </TabList>
                                </Tabs>
                            )}
                        </View>

                        {(project.externalUrl || project.mobileExternalUrl) && (
                            <View UNSAFE_className={styles.externalLink}>
                                <a
                                    target="blank"
                                    href={
                                        isMobile() && project.mobileExternalUrl
                                            ? project.mobileExternalUrl
                                            : project.externalUrl
                                    }
                                >
                                    <Button
                                        key={'cta_' + project.slug}
                                        variant="cta"
                                        target="_blank"
                                    >
                                        {project.ctaButtonLabel || 'Try it out'}
                                    </Button>
                                </a>
                                {project.tryButtonInfoText && (
                                    <span className={styles.note}>
                                        {project.tryButtonInfoText}
                                    </span>
                                )}
                            </View>
                        )}
                    </Flex>
                )}

                <div
                    className={styles.project_tab}
                    data-tab="overview"
                    data-selected={selectedTab === 'overview'}
                >
                    <div
                        className={styles.overviewContent}
                        data-carousel={project.media.length > 0}
                    >
                        {selectedTab == 'overview' && projectMedia.length > 0 && (
                            <div className={styles.carousel}>
                                <Carousel media={projectMedia} />
                            </div>
                        )}

                        <div className={styles.overview}>
                            {(project.contentSections || []).map(
                                (section, idx) => {
                                    // Getting section image
                                    const sectionImage = getImage(
                                        section?.media?.mediaObject
                                    )

                                    return (
                                        <section
                                            key={idx}
                                            className={classNames(
                                                styles.contentSection,
                                                styles[
                                                    `imageFloat${
                                                        (section.media &&
                                                            section.imageFloat) ||
                                                        'none'
                                                    }`
                                                ],
                                                styles[
                                                    `f${
                                                        section.media &&
                                                        section.imageFloat &&
                                                        section.imageMaxWidth
                                                    }`
                                                ]
                                            )}
                                        >
                                            <div
                                                className={styles.anchor}
                                                id={
                                                    section.anchor || section.id
                                                }
                                            ></div>
                                            <div
                                                className={styles.contentColumn}
                                            >
                                                {section.title && (
                                                    <h2>{section.title}</h2>
                                                )}
                                                {section.content &&
                                                    richTextToHtml(
                                                        section.content || []
                                                    )}
                                                {section.hasCta &&
                                                    project.externalUrl && (
                                                        <a
                                                            target="blank"
                                                            href={
                                                                project.externalUrl
                                                            }
                                                        >
                                                            <Button
                                                                key={
                                                                    'cta_' +
                                                                    project.slug
                                                                }
                                                                variant="cta"
                                                                target="_blank"
                                                            >
                                                                {project.ctaButtonLabel ||
                                                                    'Try it out'}
                                                            </Button>
                                                        </a>
                                                    )}
                                            </div>
                                            {section.media && (
                                                <div
                                                    className={classNames(
                                                        styles.mediaColumn,
                                                        styles[
                                                            `isVideo${
                                                                section.media
                                                                    .mpcVideoId !=
                                                                null
                                                            }`
                                                        ]
                                                    )}
                                                >
                                                    {sectionImage && (
                                                        <GatsbyImage
                                                            image={sectionImage}
                                                            alt={
                                                                section.media
                                                                    .label || ''
                                                            }
                                                            className={
                                                                styles.sectionImage
                                                            }
                                                        />
                                                    )}
                                                    {section.media
                                                        .mpcVideoId && (
                                                        <MPCVideoPlayer
                                                            mpcVideoId={
                                                                section.media
                                                                    .mpcVideoId
                                                            }
                                                        ></MPCVideoPlayer>
                                                    )}
                                                    {section.media
                                                        .embededUrl && (
                                                        <EmbdededUrl
                                                            url={
                                                                section.media
                                                                    .embededUrl
                                                            }
                                                            width={
                                                                section.media
                                                                    .embededUrlWidth
                                                            }
                                                            height={
                                                                section.media
                                                                    .embededUrlHeight
                                                            }
                                                        ></EmbdededUrl>
                                                    )}
                                                    <label>
                                                        {section.media.label ||
                                                            ''}
                                                    </label>
                                                </div>
                                            )}
                                        </section>
                                    )
                                }
                            )}
                            {project.contentSections.length == 0 &&
                                project.overviewText &&
                                richTextToHtml(project.overviewText || [])}
                        </div>
                    </div>

                    <Divider orientation="horizontal" size="S" />

                    <section className={styles.details}>
                        <h2 className={styles.projectName}>
                            {project.defaultTitle}
                        </h2>

                        <div className={styles.grid}>
                            <View>
                                {project.category.length && (
                                    <section className={styles.detailSection}>
                                        <h4 className={styles.detailName}>
                                            Category
                                        </h4>
                                        <div className={styles.detailContent}>
                                            {project.category.map(
                                                (cat, index) => (
                                                    <span
                                                        className={styles.cat}
                                                        key={index}
                                                    >
                                                        {cat.name}
                                                    </span>
                                                )
                                            )}
                                        </div>
                                    </section>
                                )}
                                {project.systemRequirements ? (
                                    <section className={styles.detailSection}>
                                        <h4 className={styles.detailName}>
                                            System Requirements
                                        </h4>
                                        <div className={styles.detailContent}>
                                            {project.systemRequirements}
                                        </div>
                                    </section>
                                ) : (
                                    ''
                                )}
                                {project.updated && (
                                    <section className={styles.detailSection}>
                                        <h4 className={styles.detailName}>
                                            Updated
                                        </h4>
                                        <div className={styles.detailContent}>
                                            {parseDate(project.updated).format(
                                                'MMM D, Y'
                                            )}
                                        </div>
                                    </section> //todo format this
                                )}
                            </View>
                            <View>
                                {project.status && (
                                    <section className={styles.detailSection}>
                                        <h4 className={styles.detailName}>
                                            Status
                                        </h4>
                                        <div className={styles.detailContent}>
                                            {project.status}
                                        </div>
                                    </section>
                                )}

                                {project.team && (
                                    <section className={styles.detailSection}>
                                        <h4 className={styles.detailName}>
                                            Team
                                        </h4>
                                        <div className={styles.detailContent}>
                                            {richTextToHtml(project.team)}
                                        </div>
                                    </section>
                                )}
                            </View>
                            <View>
                                <section className={styles.detailSection}>
                                    <h4 className={styles.detailName}>Share</h4>
                                    <div>
                                        <TwitterShareButton
                                            url={projectUrl}
                                            title={socialShareMessage}
                                            hashtags={hashtags}
                                            className={styles.shareButton}
                                            onClick={() =>
                                                trackShareClick('twitter')
                                            }
                                        >
                                            <TwitterIcon
                                                size={32}
                                                round={true}
                                            />
                                        </TwitterShareButton>
                                        <FacebookShareButton
                                            url={projectUrl}
                                            quote={socialShareMessage}
                                            hashtag={hashtagsWithHash[0]}
                                            className={styles.shareButton}
                                            onClick={() =>
                                                trackShareClick('facebook')
                                            }
                                        >
                                            <FacebookIcon
                                                size={32}
                                                round={true}
                                            />
                                        </FacebookShareButton>
                                        <LinkedinShareButton
                                            url={projectUrl}
                                            title={socialShareTitle}
                                            summary={
                                                socialShareMessageWithHashtags
                                            }
                                            className={styles.shareButton}
                                            onClick={() =>
                                                trackShareClick('linkedin')
                                            }
                                        >
                                            <LinkedinIcon
                                                size={32}
                                                round={true}
                                            />
                                        </LinkedinShareButton>
                                        <EmailShareButton
                                            url={projectUrl}
                                            subject={project.defaultTitle}
                                            body={`${socialShareMessageWithHashtags}\n`}
                                            className={styles.shareButton}
                                            onClickCapture={() =>
                                                trackShareClick('email')
                                            }
                                        >
                                            <EmailIcon size={32} round={true} />
                                        </EmailShareButton>
                                        <Copylink
                                            url={projectUrl}
                                            onCopy={() =>
                                                trackShareClick('clipboard')
                                            }
                                        ></Copylink>
                                    </div>
                                </section>
                            </View>
                        </div>
                    </section>
                </div>
                {project.relatedProjects ? (
                    <div
                        className={styles.project_tab}
                        data-tab="related"
                        data-selected={selectedTab === 'related'}
                    >
                        <div className="projects_grid">
                            {project.relatedProjects.map(relatedProject => (
                                <ProjectCard
                                    key={relatedProject.slug}
                                    {...relatedProject}
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    ''
                )}
                {project.curatedTweetsListId ? (
                    <div
                        className={styles.project_tab}
                        data-tab="discussion"
                        data-selected={selectedTab === 'discussion'}
                    >
                        <TwitterCollectionViewer
                            placeholder={
                                'Curated tweets for ' + project.defaultTitle
                            }
                            curatedTweetsListId={project.curatedTweetsListId}
                            socialHashtag={project.socialHashtag}
                        ></TwitterCollectionViewer>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </Layout>
    )
}

export default SEOProjectPageTemplate
